import React, { useEffect, useState } from 'react';
import { PageProps, HeadFC } from 'gatsby';
import { Amplify } from 'aws-amplify';
import NavBar from '../components/NavBar';
// @ts-ignore
import Seo from '../components/seo';
// @ts-ignore
import config from '../aws-exports';
import SidebarWrapper from '../components/SidebarWrapper';
import { Quiz } from '../components/Quiz';
import PiwikPro from '@piwikpro/react-piwik-pro';

let isLocalhost;
let isFeat;
let devRedirect;
if (typeof window !== 'undefined') {
  isLocalhost = !!(window.location.hostname === 'localhost');
  isFeat = !!(window.location.hostname === 'feature.d2b2yr0jqmp0n5.amplifyapp.com');
  devRedirect = isLocalhost ? 'http://localhost:8000/' : undefined;
  devRedirect = isFeat ? 'https://feature.d2b2yr0jqmp0n5.amplifyapp.com/' : devRedirect;
}
// use correct URI in the right env
const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: devRedirect ? devRedirect : 'https://www.findgiftsfor.com/',
    redirectSignOut: devRedirect
      ? 'https://feature.d2b2yr0jqmp0n5.amplifyapp.com/'
      : config.oauth.redirectSignOut // include localhost?
  }
};

Amplify.configure(updatedAwsConfig);

type DataProps = {
  site: {
    buildTime: string;
  };
};

// const lsKey = 'fgfFinderOptions';
const IndexPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  useEffect(() => {
    PiwikPro.initialize(
      'aa4790e1-d8c1-4117-9d39-8bfc787a95f7',
      'https://falofofo.containers.piwik.pro'
    );
  }, []);

  return (
    <>
      <NavBar />
      <SidebarWrapper>
        <Quiz />
      </SidebarWrapper>
    </>
  );
};

export const Head: HeadFC<DataProps> = () => <Seo title="ChatGPT Gift Search" />;

export default IndexPage;
